import { useState } from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Grid, IconButton, Typography } from '@mui/material'

import { MediaDisplay } from './MediaViewer/MediaDisplay'
import {eventService} from '../../../services/event.service'

function ManageGroupUploads(props: any) {
    const [media, setMedia] = useState<any>([])
    const [selectedInvitee, setSelectedInvitee] = useState<any>()
    const [showMedia, setShowMedia] = useState<any>(false)

    const getMedia = async (group: any) => {
        setSelectedInvitee(group)
        await eventService.getMediaByGroup(group.event_id, group.group_id)
            .then((response: any) => {
                setMedia(response.data)
                setShowMedia(true)
            })
            .catch(() => {})
    }

    return (
        <>
            {!showMedia ? (
                props?.eventGroups?.map((group: any) => (
                    <Box sx={{ width: '100%', mt: 1 }} key={group.name}>
                        <Grid
                            container
                            component="main"
                            direction="row"
                            alignItems="center"
                            sx={{
                                backgroundColor: 'white',
                                width: '100%',
                                display: 'flex',
                                p: 1,
                                borderRadius: '4px',
                                boxShadow: '0px 0px 30px #0000000D',
                                border: '1px solid',
                                borderColor: 'secondary.contrastText'
                            }}
                        >
                            <Grid item xs={5} sm={5}>
                                <Typography
                                    component="span"
                                    variant="body1"
                                    sx={{
                                        fontSize: '12px',
                                        color: 'custom.dark'
                                    }}
                                >
                                    {group?.name}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                sm={6}
                                justifyItems="right"
                                justifyContent="right"
                            >
                                <Typography
                                    component="span"
                                    variant="body1"
                                    sx={{
                                        fontSize: '11px',
                                        color: '#555555'
                                    }}
                                >
                                    {group?.admin_email}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={1}
                                sm={1}
                                justifyItems="right"
                                justifyContent="right"
                                alignItems="center"
                            >
                                <IconButton
                                    onClick={() => getMedia(group)}
                                    sx={{
                                        fontSize: '11px',
                                        textTransform: 'capitalize',
                                        backgroundColor: 'white',
                                        borderRadius: '4px',
                                        width: '24px',
                                        height: '24px',
                                        textAlign: 'right',
                                        background:
                                            '#e5ebf3 0% 0% no-repeat padding-box'
                                    }}
                                >
                                    <ArrowForwardIcon
                                        sx={{
                                            color: 'primary.light',
                                            fontSize: '16px'
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                ))
            ) : (
                <MediaDisplay
                    media={media}
                    selectedInvitee={selectedInvitee}
                    isGroup
                    back={() => setShowMedia(false)}
                />
            )}
        </>
    )
}
export { ManageGroupUploads }
