import React, { useEffect, useState } from 'react'


import { useAuth, useUser } from '@clerk/clerk-react'
import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Grid, Typography, IconButton } from '@mui/material'
import { Container } from '@mui/system'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
 
import CustomLoadingButton from '../../components/Shared/Button/CustomLoadingButton'
import UploadFileButton from '../../components/Shared/Button/UploadFileButton'
import SelectedFilesCard from '../../components/Shared/Cards/SelectedFilesCard'
import CustomDateInput from '../../components/Shared/Input/CustomDateInput'
import CustomInput from '../../components/Shared/Input/CustomInput'
import Form from '../../components/Shared/Input/Form'
import {handleFileSelect, handleFinalSubmit} from '../../helpers/uploadMediaHelper'
import Uploader from '../../hooks/Uploader'
 
const uploadSchema = yup.object().shape({
    email: yup
    .string()
    .email('The email address is not valid.') 
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'The email address is not valid.'
    ) 
    .required('*Required'),
    name: yup.string().required('*Required'),
    dob: yup.string(),
    relation: yup.string().required('*Required'),
    message: yup.string()
})

// eslint-disable-next-line max-lines-per-function
function UploadMedia(props: any) {
    const { isSignedIn } = useAuth()
    const { user } = useUser()
    const navigate = useNavigate()
    const { eventId, groupId, inviteType } = useParams()
    const [fileList, setFileList] = useState<any | null>(null)
    const [isUploadStarted, setUploadStarted] = useState(false)
    const [uploadedImages, setUploadedImages] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<File[]>([])
    const [errorMessage, setErrorMessage] = useState('')

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(uploadSchema)
    })
 
    const files = fileList ? [...fileList] : []
 
    const handleSaveMedia = async (userInputs: any) => {
        setErrorMessage('')
        if (files.length > 0) {
            setUploadStarted(true)
            setBtnLoading(true)
            let i = 1
            Array.from(files).forEach(async (file) => {
                const fileName= file.name
                const uploader = new Uploader({ fileName, file });
                await uploader.start();
                const s3Url = uploader.getS3Url();
                const url = s3Url
                const newArray: any = uploadedImages
                newArray.push({ url, key:url })
                setUploadedImages(newArray)
                if (files.length === i) {
                    await handleFinalSubmit(userInputs, eventId, groupId, uploadedImages, inviteType, navigate, setBtnLoading, setErrorMessage)
                }
                i += 1
            })
        }else{
            setErrorMessage('')
            setBtnLoading(true)
            await handleFinalSubmit(userInputs, eventId, groupId, uploadedImages, inviteType, navigate, setBtnLoading, setErrorMessage)
        }
    }
 
    
    const handleFilesUpdate = (updatedFiles: File[]) => {
        setSelectedFiles(updatedFiles)
        setFileList(updatedFiles)
    }

    useEffect(() => {
        if (isSignedIn && isSignedIn !== undefined) {
            const defaultValues: any = {
                name: user?.firstName,
                email: user?.primaryEmailAddress?.emailAddress
            }
            reset({ ...defaultValues })
        }
    }, [isSignedIn, user, reset])
 
    return (
        <Container
            sx={{
                backgroundColor: 'white',
                boxShadow: '0px 0px 30px #0000000D',
                height: '100%',
                width: '100%',
                mt: 2,
                py: 5,
                px: 5
            }}
        >
            <Box
                component="div"
                sx={{
                    px: { xs: 1, sm: 15 },
                    width: { xs: '100%', sm: '100%' }
                }}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        font: 'normal normal 600 19px/24px Inter',
                        color: 'custom.dark',
                        textAlign: 'center',
                        pt: 3,
                        pb: 5,
                        fontSize: '25px',
                        lineHeight: 1.3
                    }}
                >
               {isSignedIn ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', px: 1
                    }}>
                        <IconButton sx={{ position: 'absolute', left: 0 }} onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                        </IconButton>
                        <Box sx={{ flex: 1, textAlign: 'center',  ml: 2}}>
                        {`Hi! Let’s submit files for ${props?.event?.name}`}
                        </Box>
                    </Box>
                    ) : (
                    <Box sx={{ textAlign: 'center'}}>
                        {`Hi! Let’s submit files for ${props?.event?.name}`}
                    </Box>
                )}

               
                </Typography>
                <Form
                    buttonLabel="Sign In"
                    register={register}
                    handleSubmit={handleSubmit}
                    onSubmit={handleSubmit(handleSaveMedia)}
                    btnLoading={btnLoading}
                    submitBtn={false}
                >
                    <CustomInput
                        name="name"
                        type="text"
                        showLabel
                        placeholder="What is your name?"
                        error={errors?.name?.message}
                        xs={12}
                        md={6}
                    />
                    <CustomInput
                        name="email"
                        type="email"
                        showLabel
                        placeholder="What is your email address?"
                        error={errors?.email?.message}
                        xs={12}
                        md={6}
                        disabled
                    />
                    <CustomDateInput
                        name="dob"
                        type="text"
                        showLabel
                        placeholder="Your DOB? (Optional)"
                        xs={12}
                        md={6}
                        control={control}
                        helperText=''
                        disablePastDates={false}
                    />
                    <CustomInput
                        name="relation"
                        type="text"
                        showLabel
                        placeholder="How do you know?"
                        xs={12}
                        md={6}
                        helperText={errors?.relation ? "*Required": ''}
                    />
                    <CustomInput
                        name="message"
                        type="text"
                        showLabel
                        placeholder="What is the message?"
                        xs={12}
                        md={12}
                        multiline
                    />
                </Form>
 
                <Grid
                    container
                    component="main"
                    direction="row"
                    justifyContent="space-between"
                    spacing={1}
                >
                    <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                        <UploadFileButton
                            accept="image/*, audio/*, video/*"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileSelect(e, setFileList, setSelectedFiles)}
                        />
                        <SelectedFilesCard
                            files={selectedFiles}
                            isUploadStarted={isUploadStarted}
                            onFilesUpdate={handleFilesUpdate}
                        />

                        {errorMessage && (
                            <Typography color="#d32f2f" fontSize= "0.8571428571428571rem" fontWeight= '400' style={{ marginTop: '8px' }}>
                                {errorMessage}
                            </Typography>
                        )}
                    </Grid>
 
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        <CustomLoadingButton
                            onClick={handleSubmit(handleSaveMedia)}
                            loading={btnLoading}
                            name="Submit"
                            width="110px"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
 
export { UploadMedia }
 