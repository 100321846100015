import { useState } from 'react'

import { useSignUp } from '@clerk/clerk-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import LinkLoginWithSocial from './LinkLoginWithSocial'
import OTP from './OTP'
import { APIResponseError, parseError } from '../../../hooks/ClerkErrors'
import UserAccount from '../../../services/auth.service'
import CustomInput from '../../Shared/Input/CustomInput'
import Form from '../../Shared/Input/Form'
import CustomHeading from '../../Shared/Typography/CustomHeading'
import CustomText from '../../Shared/Typography/CustomText'

const schemaSignUp = yup.object().shape({
    email: yup
        .string()
        .email('The email address is not valid. Please correct and try again.')
        .required('*Required'),
    firstName: yup.string().required('*Required').trim(),
    lastName: yup.string().required('*Required').trim(),
    password: yup
        .string()
        .required('*Required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/^\S*$/, 'Password cannot contain spaces'),
    confirmPassword: yup
        .string()
        .required('*Required')
        .oneOf([yup.ref('password')], 'Passwords must match')
})

function RegisterForm(props: any) {
    const { signUp } = useSignUp()
    const [showOTP, setShowOTP] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const {
        setError,
        register,
        handleSubmit,
        trigger,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schemaSignUp)
    })

    const handleSendOTP = async () => {
        if (await trigger(['email'])) {
            setBtnLoading(true)
            const emailAddress = getValues('email').toLowerCase()
            const isUserExists = await (
                await UserAccount.checkUser(emailAddress)
            ).data.is_user_exists

            if (!isUserExists) {
                try {
                    const signUpAttempt = await signUp?.create({
                        emailAddress,
                        password: getValues('password'),
                        firstName: getValues('firstName'),
                        lastName: getValues('lastName')
                    })
                    await signUpAttempt
                        ?.prepareEmailAddressVerification({
                            strategy: 'email_code'
                        })
                        .then(() => {
                            setBtnLoading(false)
                            setShowOTP(true)
                        })
                } catch (error) {
                    const message = parseError(error as APIResponseError)
                    if (message.includes('Password')) {
                        setError('password', {
                            message: parseError(error as APIResponseError)
                        })
                    } else {
                        let message = parseError(error as APIResponseError)
                        if(message.includes('email_address must be a valid email address.')){
                            message = "The email address is not valid. Please correct and try again.";
                        }
                        setError('email', {
                            message
                        })
                    }

                    setBtnLoading(false)
                }
            } else {
                setError('email', {
                    message: 'Email Already Exists'
                })
                setBtnLoading(false)
            }
        }
    }

    return (
        <Box sx={{ width: '100%', textAlign: 'center', mb: { xs: -5, sm: 0 } }}>
            {!showOTP ? (
                <>
                    <CustomHeading heading="Create Account" />

                    <CustomText text="Get started its absolutely free." />

                    <Box
                        sx={{
                            textAlign: 'center',
                            px: 7,
                            mt: 4,
                            width: '100%'
                        }}
                    className= 'custom-box'
                    >
                        <Form
                            buttonLabel="Sign Up"
                            register={register}
                            handleSubmit={handleSubmit}
                            onSubmit={handleSubmit(handleSendOTP)}
                            btnLoading={btnLoading}
                            submitBtn
                        >
                            <CustomInput
                                name="firstName"
                                type="text"
                                showLabel
                                placeholder="First Name"
                                error={errors?.firstName?.message}
                                xs={12}
                                md={6}
                            />
                            <CustomInput
                                name="lastName"
                                type="text"
                                showLabel
                                placeholder="Last Name"
                                error={errors?.lastName?.message}
                                xs={12}
                                md={6}
                            />
                            <CustomInput
                                name="email"
                                type="email"
                                showLabel
                                placeholder="Email Address"
                                error={errors?.email?.message}
                                xs={12}
                                md={12}
                            />
                            <CustomInput
                                name="password"
                                type="password"
                                showLabel
                                placeholder="Password"
                                error={errors?.password?.message}
                                xs={12}
                                md={12}
                                showPasswordToggle
                            />
                            <CustomInput
                                name="confirmPassword"
                                type="password"
                                showLabel
                                placeholder="Confirm Password"
                                error={errors?.confirmPassword?.message}
                                xs={12}
                                md={12}
                                showPasswordToggle
                            />
                        </Form>

                        <LinkLoginWithSocial
                            setShowSocialLogin={props?.setShowSocialLogin}
                        />
                    </Box>
                </>
            ) : (
                <>
                    <OTP />
                </>
            )}
        </Box>
    )
}

export { RegisterForm }
