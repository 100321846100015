import { Box, Grid, Typography, useMediaQuery } from '@mui/material'

// import RectangleBoarderIcon from '../../../assets/Home/home-rectangle-box-bg.png'
// import SelfieIcon from '../../../assets/Home/home-selfie-icon.png'
// import { ReactComponent as CelebrationIcon } from '../../../assets/Home/celebration-icon.svg'
// import { ReactComponent as CelebrationSmileIcon } from '../../../assets/Home/celebration-smile-icon.svg'
// import { ReactComponent as PaperPlanColorIcon } from '../../../assets/Home/paper-plane-solid-color-ion.svg'
import EventAnniversaryIcon from '../../../assets/Home/event-anniversary-icon.png'
import EventBirthdayIcon from '../../../assets/Home/event-birthday-icon.png'
import EventCongratsIcon from '../../../assets/Home/event-congrats-icon.png'
import EventCustomIcon from '../../../assets/Home/event-custom-icon.png'
import EventWeddingIcon from '../../../assets/Home/event-engagement-icon.png'
import EventGraduationIcon from '../../../assets/Home/event-graduation-icon.png'
import EventRecognitionIcon from '../../../assets/Home/event-recognition-icon.png'
import EventThankYouIcon from '../../../assets/Home/event-thank-you-icon.png'
import EventVideoEditScreenBg from '../../../assets/Home/event-video-edit-screen-icon.png'
import EventVideoEditScreenBgSmall from '../../../assets/Home/event-video-edit-screen-small-icon.png'
import theme from '../../../styles/theme'

const eventTypes = [
    { label: 'Birthday', icon: EventBirthdayIcon },
    { label: 'Wedding', icon: EventWeddingIcon },
    { label: 'Graduation', icon: EventGraduationIcon },
    { label: 'Anniversary', icon: EventAnniversaryIcon },
    { label: 'Thank You!', icon: EventThankYouIcon },
    { label: 'Recognition', icon: EventRecognitionIcon },
    { label: 'Congrats', icon: EventCongratsIcon },
    { label: 'Custom', icon: EventCustomIcon }
]

export default function EventType() {
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Box sx={{ backgroundColor: 'secondary.dark' }}>
            <Box
                sx={{
                    pt: 15,
                    pb: { xs: 4, sm: 0 },
                    px: { xs: 2, sm: 3, md: 15 },
                    textAlign: { xs: 'center', sm: 'left' }
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h3">
                            We enable you to mark various occasions with
                            festivity.
                        </Typography>

                        <Typography variant="subtitle2">
                            Special Moments enable you to use the application on
                            various occasions other than just birthdays. You can
                            now celebrate your loved one’s anniversary, wedding
                            etc. It is easy to use and majorly helps you to
                            collect all the important images and videos in just
                            one click. Happy Celebrations to you!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            {eventTypes.map((event: any) => (
                                <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    md={3}
                                    key={event.label}
                                >
                                    <Box
                                        sx={{
                                            borderRadius: '10px',
                                            boxShadow: '3px 3px 10px #0000001A',
                                            m: 'auto',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            p: 2
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={event.icon}
                                            sx={{
                                                width: '60px',
                                                height: '60px',
                                                display: 'flex',
                                                m: 'auto'
                                            }}
                                        />

                                        <Typography
                                            sx={{ width: '100%' }}
                                            variant="caption"
                                            gutterBottom
                                        >
                                            {event.label}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Box
                            component="img"
                            src={
                                isSmall
                                    ? EventVideoEditScreenBgSmall
                                    : EventVideoEditScreenBg
                            }
                            sx={{ height: '100%', width: '100%' }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
