import { useState } from 'react'

import 'react-confirm-alert/src/react-confirm-alert.css'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'


import { ReactComponent as WelcomeIcon } from '../../../assets/welcome-icon.svg'
import CustomDateInput from '../../../components/Shared/Input/CustomDateInput'
import CustomInput from '../../../components/Shared/Input/CustomInput'
import CustomSelectInput from '../../../components/Shared/Input/CustomSelectInput'
import Form from '../../../components/Shared/Input/Form'
import CustomHeading from '../../../components/Shared/Typography/CustomHeading'
import CustomText from '../../../components/Shared/Typography/CustomText'
import { validateDates } from '../../../helpers/validateHelpers';
import {eventService} from '../../../services/event.service'

const createEventSchema = yup.object().shape({
    name: yup.string()
    .trim()
    .required('* Required'),
    event_type: yup.string().required('* Required'),
    event_date: yup.date().required('* Required'),
    actual_event_date: yup.date().required('* Required'),
    deadline: yup.date().required('* Required')
   
})

const eventType = [
    { value: 'Birthday', label: 'Birthday' },
    { value: 'Anniversary', label: 'Anniversary' },
    { value: 'Special Day', label: 'Special Day' },
    { value: '25th Anniversary', label: '25th Anniversary' },
    { value: 'Sasti Poorthi', label: 'Sasti Poorthi' },
    { value: '50th Birthday', label: '50th Birthday' }
]

function CreateEvent(props: any) {
    const navigate = useNavigate()
    const [btnLoading, setBtnLoading] = useState(false)
    const [ValidationErrors, setValidationErrors] = useState({
        actualEventDateError: '',
        deadlineError: ''
    });

    const {
        register,
        getValues,
        formState: { errors },
        control,
        handleSubmit
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(createEventSchema)
    })

    const handleCreateEvent = async () => {
        const formValues = getValues();
        formValues.name = formValues.name.trim();
        setValidationErrors({
            actualEventDateError: '',
            deadlineError: ''
          });
        
        const isValid =  validateDates(formValues, setValidationErrors)
        if (!isValid){
            return
        }
        
        const request = {
            name: formValues.name,
            event_type: formValues.event_type,
            event_date: formValues.event_date.format('YYYY-MM-DD'),
            actual_event_date: formValues.actual_event_date.format('YYYY-MM-DD'),
            deadline: formValues.deadline.format('YYYY-MM-DD'),
            description: '',
            event_image_url: '',
            publish_video_url: ''
        }
        try {
            setBtnLoading(true);
            const response = await eventService.eventCreate(request);
            props?.onClose();
            setBtnLoading(false);
            navigate(`/event-success/${response.data}`);
        } catch (error: any) {
            setBtnLoading(false);
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: { xs: '100%', sm: '340px' },
                px: { xs: 0, sm: 3 }
            }}
        >
            <Box sx={{ width: '70px', height: '70px', mb: 1 }}>
                <WelcomeIcon />
            </Box>

            <CustomHeading heading={`Let's get started!`} />

            <CustomText
                text="Tell us more about the Special Moment to create event."
                styles={{ textAlign: 'center', mb: 2 }}
            />

            <Form
                buttonLabel="Create Event"
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={handleSubmit(handleCreateEvent)}
                btnLoading={btnLoading}
                submitBtn
            >
                <CustomSelectInput
                    name="event_type"
                    type="text"
                    placeholder="What's the event?"
                    showLabel
                    error={errors?.event_type?.message}
                    xs={12}
                    md={12}
                    selectOption={eventType}
                />

                <CustomInput
                    name="name"
                    type="text"
                    showLabel
                    placeholder="Celebrating the event for?"
                    error={errors?.name?.message}
                    xs={12}
                    md={12}
                />

                <CustomDateInput
                    name="event_date"
                    type="text"
                    showLabel
                    placeholder="When will this event be celebrated?"
                    error={errors?.event_date?.message}
                    xs={12}
                    md={12}
                    control={control}
                    helperText=''
                    onKeyDown={(e) => e.preventDefault()}
                />

                <CustomDateInput
                    name="actual_event_date"
                    type="text"
                    showLabel
                    placeholder="When is the event Planned to be Published?"
                    error={errors?.actual_event_date?.message}
                    xs={12}
                    md={12}
                    control={control}
                    helperText={ValidationErrors.actualEventDateError}
                    
                />

                <CustomDateInput
                    name="deadline"
                    type="text"
                    showLabel
                    placeholder="What is the Target Date to send the Memories?"
                    error={errors?.deadline?.message}
                    xs={12}
                    md={12}
                    control={control}
                    helperText={ValidationErrors.deadlineError}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </Form>
        </Box>
    )
}

export { CreateEvent }
