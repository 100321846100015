import dayjs from 'dayjs';

export const validateDates = (formValues: any, setValidationErrors: (errors: { actualEventDateError: string; deadlineError: string }) => void) => {
    const actualEventDate = dayjs(formValues.actual_event_date);
    const deadlineDate = dayjs(formValues.deadline);
    const eventDate = dayjs(formValues.event_date);

    const ValidationErrors = {
        actualEventDateError: '',
        deadlineError: ''
    }

    if (deadlineDate.isAfter(eventDate)) {
        ValidationErrors.deadlineError = 'Event Target Date Cannot be after the event celebrated date';
    }
    else if (deadlineDate.isAfter(actualEventDate)) {
        ValidationErrors.deadlineError = "Event Target Date Cannot be after the event publish date"
    }
    else if(deadlineDate.isSame(eventDate)){
        ValidationErrors.actualEventDateError = '';
    }
    
    if (actualEventDate.isAfter(eventDate)) {
        ValidationErrors.actualEventDateError = 'Event Publish Date Cannot be after the event celebrated date';
    }
    else if (actualEventDate.isSame(eventDate)) {
        ValidationErrors.actualEventDateError = '';
    }
    if (deadlineDate.isAfter(actualEventDate)) {
        ValidationErrors.deadlineError = "Event Target Date Cannot be after the event publish date"
    }
    setValidationErrors(ValidationErrors);

    return !ValidationErrors.actualEventDateError && !ValidationErrors.deadlineError;
 
};