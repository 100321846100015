import { Box, Card, Grid, Typography, Tooltip } from '@mui/material';
import moment from 'moment';

import EventCardIcon from '../../../assets/event_card_birthday_icon2.jpg';
import ArrowForward from '../ArrowForward';

const EventDetailsCard = ({
    eventType,
    eventName,
    eventImageUrl,
    eventDate,
    redirectUrl,
    showEventDate=true
}: any) => (
    <Grid item xs={6} md={3} lg={2} xl={2}>
        <Card >
            <Box style={{ width: '100%', height: '140px'}}>
            <img
                alt="Event preview"
                style={{
                    width: '100%',
                    height: '140px',
                    borderRadius: 0,
                    objectFit: 'contain'
                }}
                src={eventImageUrl || EventCardIcon}
            />
            </Box> 
            <Box sx={{ width: '100%' }}>
                <Grid display="flex" sx={{ p: 1 }}>
                    <Grid item xs={10} md={10}>
                    <Tooltip title={`${eventName}'s`} arrow>
                    <Typography
                            gutterBottom
                            component="h6"
                            sx={{
                                font: 'normal normal 600 13px/16px Inter',
                                color: 'custom.gray',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1 !important',
                                WebkitBoxOrient: 'vertical' 
                            }}
                        >
                            <span>{eventName}&apos;s</span>
                        </Typography>
                        </Tooltip>
                    <Typography
                            gutterBottom
                            component="h6"
                            sx={{
                                font: 'normal normal 600 13px/16px Inter',
                                color: 'custom.gray',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1 !important',
                                WebkitBoxOrient: 'vertical' 
                            }}
                        >
                            {eventType}
                        </Typography>
                        {showEventDate && eventDate && (
                            <Typography
                                sx={{
                                    font: 'normal normal normal 11px/14px Inter',
                                    color: 'secondary.main'
                                }}
                            >
                                {moment(eventDate).format('MM-DD-YYYY')}
                            </Typography>
                        )}
                    </Grid>
                    <ArrowForward redirectUrl={redirectUrl} />
                </Grid>
            </Box>
        </Card>
    </Grid>
);

export default EventDetailsCard;
