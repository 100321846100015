import { useState } from 'react'

import { useSignIn } from '@clerk/clerk-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Link , Typography, Divider } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import LinkLoginWithSocial from './LinkLoginWithSocial'
import { APIResponseError, parseError } from '../../../hooks/ClerkErrors'
import CustomInput from '../../Shared/Input/CustomInput'
import Form from '../../Shared/Input/Form'
import CustomHeading from '../../Shared/Typography/CustomHeading'
import CustomText from '../../Shared/Typography/CustomText'


const schemaSignIn = yup.object().shape({
    email: yup
        .string()
        .email('The email address is invalid.')
        .required('*Required'),
    password: yup.string().required('*Required')
})

function LoginForm(props: any) {
    const { signIn } = useSignIn()
    const [btnLoading, setBtnLoading] = useState(false)

    const {
        register,
        setError,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schemaSignIn)
    })

    const handleSignIn = async (data: any) => {
        setBtnLoading(true)
        await signIn
            ?.create({
                strategy: 'password',
                password: data.password,
                identifier: data.email.toLowerCase()
            })
            .then(() => {
                window.location.href = '/redirecting-home'
                setBtnLoading(false)
            })
            .catch((err) => {
                setError('password', {
                    message: parseError(err as APIResponseError)
                })
                setBtnLoading(false)
            })
    }

    return (
        <Box sx={{ width: '100', textAlign: 'center', mb: 2 }}>
            <CustomHeading heading="Sign In" />
            <CustomText text="To continue please enter your email & password below" />

            <Box sx={{ textAlign: 'center', px: 7, mt: 4, width: '100%' }}>
                <Form
                    buttonLabel="Sign In"
                    register={register}
                    handleSubmit={handleSubmit}
                    onSubmit={handleSubmit(handleSignIn)}
                    btnLoading={btnLoading}
                    submitBtn
                >
                    <CustomInput
                        name="email"
                        type="email"
                        showLabel
                        placeholder="Enter your email"
                        error={errors?.email?.message}
                        xs={12}
                        md={12}
                    />
                    <CustomInput
                        name="password"
                        type='password'
                        showLabel
                        placeholder="Password"
                        error={errors?.password?.message}
                        xs={12}
                        md={12}
                        showPasswordToggle
                    />
                </Form>
               
                <LinkLoginWithSocial
                    setShowSocialLogin={props?.setShowSocialLogin}
                />

                <Box sx={{ mt: 1, mb: 2 }}>
                    <Divider>
                        <Typography
                            variant="body2"
                            component="span"
                            sx={{
                                color: '#1C1C1C',
                                font: 'normal normal normal 13px/16px Inter'
                            }}
                        >
                            OR
                        </Typography>
                    </Divider>
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Link href="/forgot-password" variant="body2" sx={{
                    color: '#023E8A',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontSize: '11px',
                    fontWeight: '600'
                }}>Forgot password?</Link>
                </Box>
            </Box>
        </Box>
    )
}

export { LoginForm }
