import { SecurityService } from './security.service'

class UserAccount {
    async register(data: any) {
        return (await SecurityService.createHttpHandler()).post(
            `/register/`,
            data
        )
    }

    async checkUser(email: string) {
        return (await SecurityService.createHttpHandler()).get(
            `/user/check/${email}/`
        );
    }

    async getEvents() {
        return (await SecurityService.createHttpHandler()).get(
            `/event?page_size=10&page=1`
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserAccount()
