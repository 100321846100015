import { Box } from '@mui/material'

import NavLink from './NavLink'
import NavItems from '../NavItems'

export default function SmUpMenu(props: any) {
    return (
        <>
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        sm: 'flex',
                        justifyContent: 'flex-end',
                        mr: 20
                    }
                }}
            >
                {NavItems()
                    .filter((x: any) => x.show === true)
                    .map((navLink: any) => (
                        <NavLink
                            key={navLink.label}
                            navClick={() =>
                                props?.handleNavigationChange(navLink)
                            }
                            currentLink={props?.currentLink}
                            label={navLink.label}
                            value={navLink.value}
                            type={navLink.type}
                            btnStyle={navLink.btnStyle}
                             isActive={navLink.isActive}
                        />
                    ))}
            </Box>
        </>
    )
}
