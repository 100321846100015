import { Dialog, DialogContent } from '@mui/material'; 

import {PreviewComponent} from '../Cards/PreviewCard'

interface ImagePreviewProps {
    openPreview: boolean;
    previewFile: File | null;
    isMediaFile: (file: File) => boolean;
    setOpenPreview: (open: boolean) => void;
}
export function ImagePreview({ openPreview, previewFile, isMediaFile, setOpenPreview}: ImagePreviewProps) {
    return (
        <Dialog open={openPreview} onClose={() => setOpenPreview(false)} maxWidth="md" fullWidth>
            <DialogContent>
                {previewFile && (
                    <PreviewComponent previewFile={previewFile} isMediaFile={isMediaFile} onClose={() => setOpenPreview(false)} />
                )}
            </DialogContent>
        </Dialog>
    )
}
