import { Box, Button, Card, Typography } from '@mui/material'

import { ReactComponent as UploadIcon } from '../../assets/upload-icon.svg'
import { EventTimer } from '../../components/Shared/Timer'

export default function TimeCounting(props: any) {
    return (
        <Card
            sx={{
                width: '250px',
                height: '200px',
                margin: { xs: 'auto', sm: '20px 30px auto auto' },
                bgcolor: '#08387490'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    bgcolor: '#1c75bc',
                    p: 1
                }}
            >
                <Typography variant="body2" sx={{color:"white"}}>
                    Time left to upload your files
                </Typography>
            </Box>

            <Box
                sx={{
                    px: 3,
                    py: 2
                }}
            >
                <EventTimer expiryTimestamp={props?.time} />

                <Button
                    variant="contained"
                    startIcon={<UploadIcon />}
                    onClick={props?.onClick}
                    sx={{
                        width: '104%',
                        mt: 2,
                        height: '40px',
                        color: 'white',
                        bgcolor: '#7FD14D',
                        fontSize: '13px',
                        textAlign: 'center',
                        '&:hover': {
                            bgcolor: '#7FD14D'
                        }
                    }}
                >
                    Upload Memories
                </Button>
            </Box>
        </Card>
    )
}
