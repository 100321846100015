import React, { useState } from 'react'

import { useAuth, useSignIn } from '@clerk/clerk-react'
import { Box, Grid, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { OutlinedButtons } from '../../Shared/Button/OutlinedButtons'
import CustomInput from '../../Shared/Input/CustomInput'

interface ForgotPasswordFormProps {
    onNextStep: () => void
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({onNextStep}) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [code, setCode] = useState('')
    const [successfulCreation, setSuccessfulCreation] = useState(false)
    const [secondFactor, setSecondFactor] = useState(false)
    const [error, setError] = useState('')

    const navigate = useNavigate()
    const { isSignedIn } = useAuth()
    const { isLoaded, signIn, setActive } = useSignIn()
    const { register, handleSubmit} = useForm();

    if (!isLoaded) {
        return null
    }

    if (isSignedIn) {
        navigate('/login')
    }

    const onSubmit = async () => {
        if (!successfulCreation) {
            await create()
        } else {
            await reset()
        }
    }

    async function create() {
        await signIn
            ?.create({
                strategy: 'reset_password_email_code' as any,
                identifier: email
            })
            .then(() => {
                setSuccessfulCreation(true)
                setError('')
                onNextStep()
            })
            .catch((err) => {
                const errorMessage = err.errors[0].longMessage === "Identifier is invalid."
                ? "Invalid Email"
                : err.errors[0].longMessage;
            setError(errorMessage);
            })
    }

    async function reset() {
        await signIn
            ?.attemptFirstFactor({
                strategy: 'reset_password_email_code' as any,
                code,
                password
            })
            .then((result) => {
                if (result.status === 'needs_second_factor') {
                    setSecondFactor(true)
                    setError('')
                } else if (result.status === 'complete') {
                    setActive({ session: result.createdSessionId })
                    setError('')
                }
            })
            .catch((err) => {
                setError(err.errors[0].longMessage)
            })
    }

    return (
        <Box sx={{ textAlign: 'center', px: 7, mt: 4 }}>
            <Box sx={{ width: '100', textAlign: 'center', mb: 2 }}>
                {!successfulCreation && (
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2em'
                        }}
                        onSubmit={onSubmit}
                    >
                        <Box>
                            <TextField
                                id="outlined-basic"
                                label="Enter Email"
                                value={email}
                                type="email"
                                variant="outlined"
                                onChange={(e) => setEmail(e.target.value)}
                                size="small"
                                sx={{ width: '240px' }}
                            />
                        </Box>
                        <Grid>
                            <OutlinedButtons
                                size="small"
                                onClick={onSubmit}
                                bgColor="primary.light"
                                text="Send Reset Code"
                            />
                            {error && (
                                <Typography color="error">{error}</Typography>
                            )}
                        </Grid>
                    </form>
                )}

                {successfulCreation && (
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1em'
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Box>
                            <CustomInput
                                placeholder="Enter reset code"
                                showLabel
                                value={code}
                                type="text"
                                name="resetCode"
                                onChange={(e) => setCode(e.target.value)}
                                register={register}
                                xs={12}
                                md={12}
                            />
                        </Box>
                        <Box>
                            <CustomInput
                                placeholder="Enter New Password"
                                showLabel
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                register={register}
                                name="password"
                                showPasswordToggle
                                xs={12}
                                md={12}
                            />
                        </Box>
                        <Grid>
                            <OutlinedButtons
                                size="small"
                                onClick={onSubmit}
                                bgColor="primary.light"
                                text="Reset"
                            />
                            {error && (
                                <Typography color="error">{error}</Typography>
                            )}
                        </Grid>
                    </form>
                )}
                {secondFactor && (
                    <p>2FA is required, but this UI does not handle that</p>
                )}
            </Box>
        </Box>
    )
}

export default ForgotPasswordForm
