import { useAuth } from '@clerk/clerk-react'
import { Box, Button, Link } from '@mui/material'


export default function NavLink(props: any) {
    const { isSignedIn } = useAuth()
    const isActive =
        props?.currentLink === props?.value || props?.isActive;

    return (
        <>
            {props?.type === 'link' ? (
                <Box
                    sx={{
                        px: 2,
                        pt: isSignedIn ? 0.8 : 0,
                        textAlign: 'center'
                    }}
                >
                    <Link
                        variant="button"
                        color="text.primary"
                        onClick={props?.navClick}
                        sx={{
                            fontSize: '13px',
                            color:
                                props?.currentLink === props?.value
                                    ? '#023E8A'
                                    : '#555555',
                            textDecoration: 'none',
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            textAlign: 'center'
                        }}
                    >
                        {props?.label}
                    </Link>
                    {isActive  && (
                        <Box sx={{ position: 'relative', top: '25px' }}>
                            <Box
                                sx={{
                                    width: '40px',
                                    height: '4px',
                                    background: '#023E8A',
                                    borderTopLeftRadius: '30px',
                                    borderTopRightRadius: '30px',
                                    m: 'auto'
                                }}
                            />
                        </Box>
                    )}
                </Box>
            ) : (
                <Button
                    onClick={props?.navClick}
                    sx={{
                        backgroundColor:
                            props?.btnStyle === 'solid' ? '#023E8A' : '#fff',
                        color:
                            props?.btnStyle === 'solid' ? 'white' : '#023E8A',
                        textTransform: 'capitalize',
                        fontSize: '13px',
                        cursor:'pointer',
                        px: 2,
                        mx: 2,
                        mt: -0.5,
                        border: '1px solid #023E8A',
                        '&:hover': {
                            backgroundColor:
                                props?.btnStyle === 'solid'
                                    ? '#fff'
                                    : '#023E8A',
                            color:
                                props?.btnStyle === 'solid'
                                    ? '#023E8A'
                                    : '#fff'
                        }
                    }}
                >
                    {props?.label}
                </Button>
            )}
        </>
    )
}
