import { useAuth } from '@clerk/clerk-react';
import { useLocation } from 'react-router-dom'

type NavItemTypes = {
    value: string;
    label: string;
    type: 'link' | 'button'; 
    btnStyle?: string;
    show?: boolean;
    isSingleNavigation: boolean;
    isActive?: boolean;
  };


export default function NavItems() {
    const { isSignedIn } = useAuth();
    const location = useLocation();
    const isDashboardPath = location.pathname.startsWith('/view-event');
    const isMyInvitesPath =  /^\/upload-images\/\w+/.test(location.pathname);
    const isMymemoriesPath = /^\/memories-collection\/\w+/.test(location.pathname);

    const baseNavItems: NavItemTypes[] = [
        {
            value: '/',
            label: 'Home',
            type: 'link',
            show: !isSignedIn,
            isSingleNavigation: true
        },
        {
            value: '#about-us',
            label: 'About Us',
            type: 'link',
            show: !isSignedIn,
            isSingleNavigation: true
        },
        {
            value: '#how-it-works',
            label: 'How It works',
            type: 'link',
            show: !isSignedIn,
            isSingleNavigation: true
        },
        {
            value: 'login',
            label: 'Sign In/Sign up',
            type: 'button',
            btnStyle: 'solid',
            show: !isSignedIn,
            isSingleNavigation: false
        },
        {
            value: '#contact-us',
            label: 'Contact Us',
            type: 'button',
            btnStyle: 'outline',
            show: !isSignedIn,
            isSingleNavigation: true
        },
        {
            value: '/dashboard',
            label: 'My Dashboard',
            type: 'link',
            show: isSignedIn,
            isSingleNavigation: false
        }, 
        {
            value: '/invites',
            label: 'My Invites',
            type: 'link',
            show: isSignedIn,
            isSingleNavigation: false
        }
    ]


    const baseNavItems2: NavItemTypes[] = [
        {
            value: '/',
            label: 'Home',
            type: 'link',
            show: isSignedIn &&  location.pathname === '/',
            isSingleNavigation: true
        },
        {
            value: '#about-us',
            label: 'About Us',
            type: 'link',
            show: isSignedIn && location.pathname === '/',
            isSingleNavigation: true
        },
        {
            value: '#how-it-works',
            label: 'How It works',
            type: 'link',
            show: isSignedIn && location.pathname === '/',
            isSingleNavigation: true
        },

        {
            value: '#contact-us',
            label: 'Contact Us',
            type: 'button',
            btnStyle: 'outline',
            show: isSignedIn &&  location.pathname === '/',
            isSingleNavigation: true
        },

        {
            value: '/dashboard',
            label: 'My Dashboard',
            type: 'link',
            show: isSignedIn  &&  location.pathname !== '/',
            isSingleNavigation: false,
            isActive: isDashboardPath
        },
        {
            value: '/invites',
            label: 'My Invites',
            type: 'link',
            show: isSignedIn  &&  location.pathname !== '/',
            isSingleNavigation: false,
            isActive: isMyInvitesPath
        },
        {
            value: '/memories',
            label: 'My memories',
            type: 'link',
            show: isSignedIn  &&  location.pathname !== '/',
            isSingleNavigation: false,
            isActive: isMymemoriesPath
        }
    ]

    
    const navItems = isSignedIn ? [...baseNavItems2] : baseNavItems;

    return navItems
}
