import React, { useEffect, useState, useRef } from 'react'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Avatar,
    Card,
    Chip,
    Grid,
    LinearProgress,
    Typography,
    Tooltip
} from '@mui/material'

import SelectedFilesDeletePreviewCard from './SelectedFilesDeletePreviewCard'
import {ImagePreview} from '../Dialogs/DialogImagePreview'

export default function SelectedFilesCard(props: any) {
    const { files, onFilesUpdate } = props;
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [allFiles, setAllFiles] = useState(files)
    const [previewFile, setPreviewFile] = useState<File | null>(null)
    const [openPreview, setOpenPreview] = useState(false)
    const changeImageInputRef = useRef<HTMLInputElement>(null);

    function handlePreview(file: File) {
        setPreviewFile(file)
        setOpenPreview(true)
    }

    function handleDelete(fileIndex: number) {
        const updatedFiles = allFiles.filter((_: any, index: any) => index !== fileIndex)
        setAllFiles(updatedFiles)
        if (onFilesUpdate) {
            onFilesUpdate(updatedFiles)
        }
    }

    function handleEdit(fileIndex: number){
        setEditingIndex(fileIndex)
        if(changeImageInputRef.current) {
            changeImageInputRef.current.click()
        }
    }

    function handleFileChange(e:React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && editingIndex !== null) {
            const newFile = e.target.files[0];

            if (editingIndex>=0 && editingIndex<allFiles.length){
                const updatedFiles = [...allFiles];
                updatedFiles[editingIndex] = newFile;
                setAllFiles(updatedFiles); 
                setEditingIndex(null);

                if (onFilesUpdate) {
                    onFilesUpdate(updatedFiles); 
                }
            }
        }
    }

    useEffect(() => {
        setAllFiles(files)
    }, [files])

    const isMediaFile = (file: File): boolean =>
        file.type.startsWith('audio/') || file.type.startsWith('video/');

    return (
        <>
            {allFiles &&
                allFiles.map((file: any, index: number) => {
                    if (typeof file !== "string") {
                        return (
                            <Card
                                key={`${file.name}-${file.lastModified}`}
                                sx={{
                                    boxShadow: '0px 0px 30px #0000000D',
                                    border: '1px solid',
                                    borderColor: 'secondary.contrastText',
                                    borderRadius: '4px',
                                    p: 2,
                                    my: 1
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="left"
                                    alignItems="center"
                                >
                                    <Grid item xs={2}>
                                        <Avatar
                                            src={URL.createObjectURL(file)}
                                            sx={{ borderRadius: 0 }}
                                        />
                                    </Grid>
    
                                    {isMediaFile(file) ? (
                                        <>
                                            <Grid item xs={4}>
                                            <Tooltip title={file?.name} placement='top'>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        font: 'normal normal normal 13px/14px Inter',
                                                        color: 'custom.dark',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: '1 !important',
                                                        WebkitBoxOrient: 'vertical'
                                                    }}
                                                >
                                                    {file?.name}
                                                </Typography>
                                                </Tooltip>
                                            </Grid>
    
                                            <Grid item xs={1}>
                                                <VisibilityIcon
                                                    onClick={() => handlePreview(file)}
                                                    style={{ fontSize: 20, cursor: 'pointer' }}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={5}>
                                            <Tooltip title={file?.name} placement='top'>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        font: 'normal normal normal 13px/14px Inter',
                                                        color: 'custom.dark',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: '1 !important',
                                                        WebkitBoxOrient: 'vertical'
                                                    }}
                                                >
                                                    {file?.name}
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                        
                                    )}
    
                                    <Grid item xs={1}>
                                        <EditIcon
                                            style={{ fontSize: 20, cursor: 'pointer' }}
                                            onClick={() => handleEdit(index)}
                                        />
                                    </Grid>
    
                                    <Grid item xs={1}>
                                        <DeleteIcon
                                            style={{ fontSize: 20, cursor: 'pointer' }}
                                            onClick={() => handleDelete(index)}
                                        />
                                    </Grid>
    
                                    <Grid item xs={3}>
                                        {!props?.isUploadStarted ? (
                                            <Chip
                                                label="Not started"
                                                variant="outlined"
                                                sx={{
                                                    font: 'normal normal medium 11px/14px Inter',
                                                    color: '#7FD14D',
                                                    bgcolor: '#f2faed',
                                                    borderColor: '#f2faed',
                                                    borderRadius: '4px',
                                                    height: '26px'
                                                }}
                                            />
                                        ) : (
                                            <LinearProgress />
                                        )}
                                    </Grid>
                                </Grid>
                            </Card>
                        );
                    } 
                    
                        return (
                            <SelectedFilesDeletePreviewCard 
                                props={props}
                                files={files}
                                onFilesUpdate={onFilesUpdate}
                                isUploadStarted={props?.isUploadStarted}
                                setShowPreview={props?.setShowPreview}
                            />
                        );
                    
                })}
            
            <input
                ref={changeImageInputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e)}
            />
            <ImagePreview 
                openPreview={openPreview} 
                previewFile={previewFile} 
                isMediaFile={isMediaFile} 
                setOpenPreview={setOpenPreview}
            />
        </>
    );
}    