import React, { useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Avatar,
    Card,
    Chip,
    Grid,
    LinearProgress,
    Typography,
    CircularProgress,
    Tooltip
} from '@mui/material'

import {eventService} from '../../../services/event.service'


export default function SelectedFilesDeletePreviewCard(props: any) {
    const { files, setShowPreview} = props;
    const [allFiles, setAllFiles] = useState(files)
    const [loading, setLoading] = useState(false);
  
    const handleDelete = async(fileUrl: string) => {
        setLoading(true)

        const request = {
            publish_video_url: fileUrl
        }
        try{
            await eventService.updatePublishVideoURL(props?.props?.event?.event?.event_id, request)
            setAllFiles([])
        }finally{
            setLoading(false)
        }
    }

return (
    <>
        {allFiles &&
            allFiles?.map((file: any) => (
                <Card
                    key={`${file.name}-${file.lastModified}`}
                    sx={{
                        boxShadow: '0px 0px 30px #0000000D',
                        border: '1px solid',
                        borderColor: 'secondary.contrastText',
                        borderRadius: '4px',
                        p: 2,
                        my: 1
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="left"
                        alignItems="center"
                    >
                        <Grid item xs={2}>
                            <Avatar
                            src={file}
                            sx={{ borderRadius: 0 }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                        <Tooltip title={file.split('/').pop()} placement='top'>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    font: 'normal normal normal 13px/14px Inter',
                                    color: 'custom.dark',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '1 !important',
                                    WebkitBoxOrient: 'vertical'
                                }}
                            >
                                {file.split('/').pop()}
                            </Typography>
                        </Tooltip>
                        </Grid>

                        <Grid item xs={1}>
                            <VisibilityIcon 
                                onClick={setShowPreview}
                                style={{fontSize: 20, cursor: 'pointer'}}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <DeleteIcon
                                style={{fontSize: 20, cursor: 'pointer'}}
                                onClick={() => handleDelete(file)}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            {!props?.isUploadStarted ? (
                                <Chip
                                    label="Uploaded"
                                    variant="outlined"
                                    sx={{
                                        font: 'normal normal medium 11px/14px Inter',
                                        color: '#7FD14D',
                                        bgcolor: '#f2faed',
                                        borderColor: '#f2faed',
                                        borderRadius: '4px',
                                        height: '26px'
                                    }}
                                />
                            ) : (
                                <LinearProgress />
                            )}
                        </Grid>
                        <Grid item xs={1}>
                            {loading &&
                                <CircularProgress
                                    size={30}
                                />
                            }

                        </Grid>
                    </Grid>
                </Card>
            ))}
    </>
)
}
