import { InputHTMLAttributes, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, TextField, IconButton, InputAdornment} from '@mui/material';

import ErrorValidationMessage from '../ErrorMessage/ErrorValidationMessage';
import Label from '../Label/Label';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: any;
    register?: any;
    wrapperClass?: string;
    className?: string;
    placeholder?: any;
    xs?: any;
    md?: any;
    multiline?: boolean;
    showLabel?: boolean;
    sx?: any;
    type?: string;
    helperText?: string | undefined;
    showPasswordToggle?: boolean;
}

function CustomInput({
    register,
    name,
    error,
    placeholder,
    multiline,
    showLabel,
    helperText,
    sx,
    type,
    showPasswordToggle,
    disabled,
    ...rest
}: InputProps) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev); 
       
    };
    
    return (
        <>
            {showLabel && (
                <Box display="flex" alignItems="start" flexDirection="column">
                    <Label title={placeholder} />
                </Box>
            )}
            <TextField
                {...register(name)}
                {...rest}
                type={showPasswordToggle && showPassword ? 'text' : type === undefined ? 'text' : type}
                name={name}
                variant="outlined"
                placeholder={placeholder}
                size="small"
                multiline={multiline}
                rows={multiline ? 4 : 1}
                disabled={disabled}
                sx={{
                    ...sx,
                    borderRadius: '4px',
                    borderColor: 'secondary.contrastText',
                    color: 'primary.contrastText',
                    width: '100%',
                    '& .MuiInputBase-root': {
                        mt: 0
                    }
                }}
                InputProps={showPasswordToggle ? {
                    style: { fontSize: '14px'},
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                                disabled={disabled}
                            >
                                {showPassword ? <VisibilityOffIcon fontSize="small"/> : <VisibilityIcon fontSize="small"/>}
                            </IconButton>
                        </InputAdornment>
                    )
                } : undefined}
            />
            {helperText && (
                <Box mt={1} sx={{ color: '#d32f2f', fontSize: '0.8571428571428571rem', fontWeight: 400 }}>
                    {helperText}
                </Box>
            )}
             {error && <ErrorValidationMessage message={error} />}
        </>
    );
}

export default CustomInput;
