import React from 'react'

import { eventService } from '../services/event.service'

export const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>, setFileList: any, setSelectedFiles: any) => {
    if (e.target.files){
        const selectedFiles = Array.from(e.target.files);
        setFileList((prevFiles: any)  => [...(prevFiles || []), ...selectedFiles]); 
        setSelectedFiles((prevFiles: any) => [...(prevFiles || []), ...selectedFiles]); 
    }
    e.target.value= '';
}

export const handleFileSelectForUploadPublish = (e: React.ChangeEvent<HTMLInputElement>, setFileList: any, setSelectedFiles: any) => {
    if (e.target.files){
        const selectedFiles = Array.from(e.target.files);
        setFileList(selectedFiles); 
        setSelectedFiles(selectedFiles as File[]); 
    }
    e.target.value= '';
}
export const handleFinalSubmit = async (
    userInputs: any, 
    eventId: string | undefined, 
    groupId: string | undefined, 
    uploadedImages: never[] | undefined, 
    inviteType: string | undefined, 
    navigate: any, 
    setBtnLoading: (loading: boolean) => void,
    setErrorMessage: (message: string) => void) => {

    const request = {
        event_id: eventId,
        group_id: groupId,
        document_urls: uploadedImages,
        message: userInputs.message,
        email: userInputs.email,
        name: userInputs.name,
        invite_type: inviteType
    }

    if ((uploadedImages && uploadedImages.length === 0) && userInputs.message === ''){
        setBtnLoading(false)
        setErrorMessage("Message or video required to send");
        return
    }
    
    await eventService.uploadDocuments(request)
        .then(() => {
            setBtnLoading(false)
            navigate('/upload-success')
        })
        .catch(() => {
            setBtnLoading(false)
        })
}

