import { useState } from 'react'


import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ReactComponent as SuccessIcon } from '../../../../assets/welcome-icon.svg'
import CustomLoadingButton from '../../../../components/Shared/Button/CustomLoadingButton'
import CustomizedDialogs from '../../../../components/Shared/Dialogs/CustomizedDialogs'
import DialogSuccess from '../../../../components/Shared/Dialogs/DialogSuccess'
import CustomInput from '../../../../components/Shared/Input/CustomInput'
import Form from '../../../../components/Shared/Input/Form'
import contactUsService from '../../../../services/contact-us.service'

const schemaContactForm = yup.object().shape({
    name: yup.string().required('*Required'),
    email: yup.string().required('*Required'),
    message: yup.string().required('*Required')
})

export default function ContactForm() {
    const [btnLoading, setBtnLoading] = useState(false)
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schemaContactForm)
    })

    const handleContactSubmit = async (data: any) => {
        setBtnLoading(true)
        await contactUsService
            ?.contactUs(data)
            .then(() => {
                reset({
                    name: '',
                    email: '',
                    message: ''
                })
                setBtnLoading(false)
                setShowSuccessDialog(true)
            })
            .catch(() => {
                setBtnLoading(false)
            })
    }

    const customInputStyle = {
        width: '100%',
        '& .MuiInputBase-input': {
            color: 'white',
            borderRadius: '4px'
        },
        '& fieldset': {
            borderRadius: '4px',
            border: 'none',
            background: '#F8F8F820'
        }
    }

    const customBtnStyle = {
        height: '50px',
        width: '150px',
        font: 'normal normal 600 13px/16px Inter',
        textTransform: 'none',
        borderRadius: '4px',
        color: 'white',
        backgroundColor: 'custom.lightGreen',
        fontWeightRegular: 600,
        border: '1px solid',
        borderColor: 'custom.lightGreen',
        '&:hover': {
            color: 'white',
            border: '1px  solid white'
        },
        ' .MuiLoadingButton-loadingIndicator': {
            color: 'white'
        }
    }

    return (
        <>
            <Form
                buttonLabel="Sign In"
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={handleSubmit(handleContactSubmit)}
                btnLoading={btnLoading}
                spacing={3}
                sx={{
                    pl: { xs: 0, sm: 0, md: 20 },
                    pr: 0,
                    mt: 3
                }}
            >
                <CustomInput
                    name="name"
                    type="text"
                    placeholder="Enter your name"
                    showLabel={false}
                    error={errors?.name?.message}
                    xs={12}
                    md={12}
                    sx={customInputStyle}
                />
                <CustomInput
                    name="email"
                    type="email"
                    showLabel={false}
                    placeholder="Enter your email"
                    error={errors?.email?.message}
                    xs={12}
                    md={12}
                    sx={customInputStyle}
                />
                <CustomInput
                    name="message"
                    type="text"
                    showLabel={false}
                    placeholder="Enter your message"
                    error={errors?.message?.message}
                    xs={12}
                    md={12}
                    multiline
                    sx={customInputStyle}
                />
            </Form>

            <Grid item xs={12} md={12}>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <CustomLoadingButton
                        onClick={handleSubmit(handleContactSubmit)}
                        loading={btnLoading}
                        name="Submit"
                        sx={customBtnStyle}
                    />
                </Box>
            </Grid>

            <CustomizedDialogs
                open={showSuccessDialog}
                onClose={() => setShowSuccessDialog(false)}
                onManage={() => setShowSuccessDialog(false)}
                header="Thank You!"
                message="Thank you for reaching us, we will get back to you!"
                continueBtn="Close"
                isClosed
                icon={SuccessIcon}
                component={DialogSuccess}
            />
        </>
    )
}
