import { Box, Typography } from '@mui/material'

import { EventCard } from '../EventCard'

function PublishedEvents(props: any) {
    const {events} = props;
    const publishedEvents = events?.filter(
        (x: any) => x.status === 'Published'

    ) 
    
    return (
        <>
        <EventCard events={publishedEvents} />
            {publishedEvents.length === 0 &&(
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        mt: 25,
                        mx: 'auto',
                        width: '400',
                        textAlign: 'center'
                    }}
                >
                    <Typography
                        component="span"
                        variant="body2"
                        sx={{
                            font: 'normal normal normal 13px/16px Inter',
                            color: 'secondary.main'
                        }}
                    >
                        You have not published any events yet
                    </Typography>
                </Box>
            )}
        </>
    )
}
export { PublishedEvents }
