import React from 'react';

import { Box, Typography } from '@mui/material'

import { EventCard } from '../EventCard'

function ArchiveEvents(props: any) {
    const {events} = props;

    const ArchivedEvents = events?.filter(
        (x: any) => x.status === 'Archived'

    ) 
    
    return (
        <>
        <EventCard events={ArchivedEvents} />
            {ArchivedEvents.length === 0 &&(
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        mt: 25,
                        mx: 'auto',
                        width: '400',
                        textAlign: 'center'
                    }}
                >
                    <Typography
                        component="span"
                        variant="body2"
                        sx={{
                            font: 'normal normal normal 13px/16px Inter',
                            color: 'secondary.main'
                        }}
                    >
                        You dont have any Archive events yet
                    </Typography>
                </Box>
            )}
        </>
    )
}
export { ArchiveEvents }
