import toast from 'react-hot-toast';

import  Uploader from '../hooks/Uploader'
import {eventService} from '../services/event.service'

export const handleFileSelect = async (
    props: any,
    setTempEventImage: (image: string) => void,
    files: FileList | null) => {
    if (!files || files.length === 0) {
        return;
    }

    try {
        const file = files[0];
        const fileName = file.name;
        const uploader = new Uploader({ fileName, file });
        await uploader.start();
        const s3Url = uploader.getS3Url();
        setTempEventImage(s3Url);
        const request = { event_image_url: s3Url };
        await eventService.eventUpdate(props?.event.event_id, request);
        toast.success('Profile image uploaded!');
    } catch (error) {
        toast.error('Failed to upload image');
    }
};
