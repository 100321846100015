import CloseIcon from '@mui/icons-material/Close'
import { Card, CardMedia, Typography, Box, IconButton } from '@mui/material'

export const PreviewComponent = ({previewFile, isMediaFile, onClose}: {previewFile: File, isMediaFile: (file: File) => boolean, onClose:() => void}) => (
        <>
            <Card sx={{p: 2, my: 2, boxShadow: 3, position:'relative'}}>
                <IconButton 
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        zIndex: 10,
                        color: 'red'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    {isMediaFile(previewFile) ? (
                        previewFile.type.startsWith('audio/') ? (
                            <CardMedia component="audio" controls sx={{width: '100%'}}>
                                <source src={URL.createObjectURL(previewFile)} type={previewFile.type} />
                            </CardMedia>
                        ): (
                            <CardMedia component="video" controls sx={{width: '100%'}}>
                                <source src={URL.createObjectURL(previewFile)} type={previewFile.type} />
                            </CardMedia>
                            
                        )
                    ):(
                        <Typography>
                                File type not supported for preview.
                        </Typography>
                    )}

                </Box>
            </Card>
        </>
    )