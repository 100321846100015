/* eslint-disable camelcase */
import { useState } from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Grid, IconButton, Typography } from '@mui/material'

import { MediaDisplay } from "../ViewEvent/ManageUploads/MediaViewer/MediaDisplay"

interface Invitee {
    uploader_name: string;
    uploader_email: string;
    message: string;
    document_urls: string[];
}

interface ManageInviteeUploadsProps {
    props: {
        greetings: Invitee[];
    };
}

export default function ManageInviteeUploads({ props }: ManageInviteeUploadsProps) {
    const [showMedia, setShowMedia] = useState<boolean>(false)
    const [selectedInvitee, setSelectedInvitee] = useState<Invitee | null>(null)

    const getMedia = (invitee: Invitee) => {
        setSelectedInvitee(invitee)
        setShowMedia(true)
    }

    return (
        <>
            {!showMedia ? (
                props?.greetings?.map((invitee: Invitee) => (
                    <Box sx={{ width: '100%', mt: 2 }} key={invitee.uploader_name}>
                        <Grid
                            container
                            component="main"
                            direction="row"
                            alignItems="center"
                            sx={{
                                backgroundColor: 'white',
                                width: '100%',
                                display: 'flex',
                                p: 1,
                                borderRadius: '4px',
                                boxShadow: '0px 0px 30px #0000000D',
                                border: '1px solid',
                                borderColor: 'secondary.contrastText'
                            }}
                        >
                            <Grid item xs={5} sm={4}>
                                <Typography
                                    component="span"
                                    variant="body1"
                                    sx={{
                                        fontSize: '12px',
                                        color: 'custom.dark'
                                    }}
                                >
                                    {invitee?.uploader_name ?? invitee?.uploader_email}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                md={7}
                                justifyItems="right"
                                justifyContent="right"
                            >
                                <Typography
                                    component="span"
                                    variant="body1"
                                    sx={{
                                        fontSize: '11px',
                                        color: '#555555'
                                    }}
                                >
                                    {invitee?.uploader_email}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={1}
                                md={1}
                                justifyItems="right"
                                justifyContent="right"
                                alignItems="center"
                            >
                                {((invitee?.document_urls && invitee.document_urls?.length > 0) || invitee?.message)&& (
                                    <IconButton
                                        onClick={() => getMedia(invitee)}
                                        sx={{
                                            fontSize: '11px',
                                            textTransform: 'capitalize',
                                            backgroundColor: 'white',
                                            borderRadius: '4px',
                                            width: '24px',
                                            height: '24px',
                                            textAlign: 'right',
                                            background:
                                                '#e5ebf3 0% 0% no-repeat padding-box'
                                        }}
                                    >
                                        <ArrowForwardIcon
                                            sx={{
                                                color: 'primary.light',
                                                fontSize: '16px'
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                ))
            ) : (
                selectedInvitee && (
                    <Box sx={{ mt: 2 }}>
                        <MediaDisplay
                            media={selectedInvitee?.document_urls}
                            back={() => setShowMedia(false)}
                            selectedInvitee={selectedInvitee}
                        />
                    </Box>
                )
            )}
        </>
    )
}
