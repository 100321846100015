import { useState } from 'react'

import { Box, Card, Checkbox, Grid, Hidden, Typography } from '@mui/material'

import { ReactComponent as BellIcon } from '../../../../assets/bell-solid.svg'
import { ReactComponent as RemainderSuccessIcon } from '../../../../assets/remainder-success-icon.svg'
import CustomLoadingButton from '../../../../components/Shared/Button/CustomLoadingButton'
import CustomizedDialogs from '../../../../components/Shared/Dialogs/CustomizedDialogs'
import DialogSuccess from '../../../../components/Shared/Dialogs/DialogSuccess'
import NoDataLabel from '../../../../components/Shared/Label/NoDataLabel'
import {eventService} from '../../../../services/event.service'

function ListGroups(props: any) {
    const [selectedInvitees, setSelectedInvitees] = useState([{}])
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const handleChange = (value: any) => {
        let newArray
        if (
            !(
                selectedInvitees.filter(
                    (e: any) => e?.admin_email === value.admin_email
                ).length > 0
            )
        ) {
            newArray = selectedInvitees
            newArray.push({
                email: value.admin_email,
                group_id: value.group_id
            })
        } else {
            newArray = selectedInvitees.filter(
                (item: any) => item?.admin_email !== value
            )
        }
        setSelectedInvitees(newArray)
    }

    const handleSendReminder = async () => {
        selectedInvitees.shift()
        if (selectedInvitees.length > 0) {
            const request = {
                invitees: selectedInvitees,
                event_id: props?.event.event_id
            }
            setBtnLoading(true)
            await eventService.sendRemainderEmail(request)
                .then(() => {
                    setSelectedInvitees([{}])
                    setShowSuccessDialog(true)
                    setBtnLoading(false)
                })
                .catch(() => {
                    setBtnLoading(false)
                })
        }
    }

    return (
        <>
            {props?.eventGroups?.map((invitee: any) => (
                <Card
                    key={invitee.name}
                    sx={{
                        boxShadow: '0px 0px 30px #0000000D',
                        border: '1px solid',
                        borderColor: 'secondary.contrastText',
                        borderRadius: '4px',
                        m: 2
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={2} sm={1}>
                            <Checkbox
                                value={invitee.email}
                                onChange={() => handleChange(invitee)}
                            />
                        </Grid>
                        <Grid item xs={10} sm={5}>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    font: 'normal normal normal 13px/14px Inter',
                                    color: 'custom.dark',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '1 !important',
                                    WebkitBoxOrient: 'vertical' 
                                }}
                            >
                                {invitee.name}
                            </Typography>
                        </Grid>
                        <Hidden only="xs">
                            <Grid item xs={5} sm={6}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        font: 'normal normal normal 13px/14px Inter',
                                        color: 'custom.dark',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1 !important',
                                        WebkitBoxOrient: 'vertical' 
                                    }}
                                >
                                    {invitee.admin_email}
                                </Typography>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Card>
            ))}
            {props?.eventGroups?.length > 0 && (
                <>
                    <Box sx={{ textAlign: 'right', m: 2 }}>
                        <CustomLoadingButton
                            startIcon={<BellIcon />}
                            onClick={handleSendReminder}
                            loading={btnLoading}
                            name="Send Reminders"
                            width="auto"
                        />
                    </Box>

                    <CustomizedDialogs
                        open={showSuccessDialog}
                        onClose={() => setShowSuccessDialog(false)}
                        header="Invitation Sent Successfully"
                        message="Your invitation to your invitees is on their way"
                        continueBtn="Manage Groups"
                        icon={RemainderSuccessIcon}
                        component={DialogSuccess}
                    />
                </>
            )}
            {props?.eventGroups?.length === 0 && <NoDataLabel />}
        </>
    )
}

export { ListGroups }
