import { useEffect, useState } from 'react';

import { ClerkProvider } from '@clerk/clerk-react'
import { CircularProgress } from '@mui/material';
import { Toaster } from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

import { AppRoutes } from './AppRoutes'
import Navbar from '../components/Navbar'

function App() {
    const frontendApi: any = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY
    const publishableKey: any = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const isForgotPasswordPage = location.pathname === '/forgot-password';
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false)
        }, 1000)
        return () => clearTimeout(timer)
    },[])

    return (
        <ClerkProvider
            frontendApi={frontendApi}
            publishableKey={publishableKey}
        >
            {loading ? (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}>
                    <CircularProgress/>
                </div>
            ): (
                <>
                    {!isLoginPage && !isForgotPasswordPage && <Navbar />}
                    <Toaster position="top-center" reverseOrder={false} />
                    <AppRoutes />
                </>
            )}
            
        </ClerkProvider>
    )
}

export default App