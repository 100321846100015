import axios, { AxiosInstance } from 'axios'

import ClerkToken from './security/clerk-token.service'

declare global {
    interface Window {
        Clerk: any
    }
}
class SecurityService {
    static async createHttpHandler () : Promise<AxiosInstance>{
        const token = await ClerkToken.getToken()
        if (token){
            localStorage.setItem('authToken', token);
        }
        const instance = axios.create({
            baseURL: process.env.REACT_APP_PUBLIC_BASE_API_URL,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json' || 'multipart/form-data'
            }
        })
        instance.interceptors.request.use(
            (config: any) => {
                const storedToken = localStorage.getItem('authToken');
                if (storedToken) {
                    config.headers = {
                        Authorization: `Bearer ${storedToken}`
                    }
                }
                return config
            },
            (error: any) => Promise.reject(error)
        )
        instance.interceptors.response.use(
            (response: any) => response,
            (error: any) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/'; 
                }
                return Promise.reject(error);
            }
        );
        return instance
    }
}

export { SecurityService }
